import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpoon, faStar } from "@fortawesome/free-solid-svg-icons";

const image = "./Images/testimonial-bg.jpg";

const StyledContainer = styled(Container)`
  background-image: url(${image});
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  height: 900px;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  @media(max-width: 600px){
   min-height: 1000px;
  }
`;

export default function CarouselTestimonials() {
  const Recenzioni = [
    {
      id: 0,
      text: "Rioxoris è un vero e proprio agriturismo a conduzione familiare che propone una cucina deliziosa con prodotti a km zero. Ideale per una vacanza lontano dai ritmi frenetici della città, l'agriturismo, immerso nel verde delle campagne di San Nicolò d'Arcidano, ha camere pulite, spaziose e dotate di tutti i servizi necessari. Deliziosi i malloreddusu con brodo di pecora proposti dallo chef Martino ma anche i salumi...",
      testimone: "Maria Rita",
    },
    {
      id: 1,
      text: "Quando cercate pace, relax, persone gentili e divertenti, andate a Rioxoris e vi troverete immersi nella natura, circondati dal silenzio e dai rumori della natura. Senza dimenticare che troverete anche ottimo cibo. Questo è quello che ho trovato, quando non sapevo nemmeno di cercarlo e di poterlo trovare lì. Grazie a Martino, Beppe, Bianca e a tutte le belle persone che mi hanno accolto così calorosamente. A presto!",
      testimone: "Andrea",
    },
   
    {
      id: 2,
      text: "Abbiamo trascorso un soggiorno meraviglioso all’Agriturismo Rioxoris. La calorosa ospitalità dei proprietari ci ha fatto sentire come a casa. La cucina tradizionale sarda è stata una vera delizia, con piatti preparati con ingredienti freschi e locali. Le camere erano confortevoli e ben arredate, e la vista sulla campagna circostante era mozzafiato. Un luogo ideale per chi cerca tranquillità e autenticità. Non vediamo l’ora di tornare!",
      testimone: "Valentina",
    },
    {
      id: 3,
      text: "Il nostro soggiorno all’Agriturismo Rioxoris è stato semplicemente perfetto. La struttura è ben curata e immersa in un paesaggio naturale stupendo. Le camere sono pulite e accoglienti, e la colazione con prodotti fatti in casa è stata un vero piacere. Il personale è stato sempre disponibile e cordiale, facendoci sentire davvero benvenuti. Un’esperienza autentica e rilassante che consiglio a tutti coloro che desiderano scoprire la vera Sardegna.",
      testimone: "Fabio",
    },
  ];

  return (
    <>
      <Carousel>
        {Recenzioni.map(({ id, text, testimone }) => (
          
           <Carousel.Item key={id} >
           <StyledContainer fluid >
             <Carousel.Caption className="d-flex flex-column align-items-center">
               <span className="decoro-small decoro">Testimonials</span>
               <div className="d-flex justify-content-center my-2">
                 <div className="spoon-line"></div>
                 <FontAwesomeIcon className="spoon mx-2" icon={faSpoon} color="#c1a35f" size="2x" />
                 <div className="spoon-line"></div>
               </div>
               <div className="text-center">
                 <h2 className="titolo-small titolo">Cosa dicono i nostri clienti</h2>
                 {[...Array(5)].map((_, i) => (
                   <FontAwesomeIcon key={i} icon={faStar} color="#c1a35f" size="2x" />
                 ))}
               </div>
             
               <p className="testimone-text mt-5">{text}</p>
               <p className="testimone">{testimone}</p>
             
              
             </Carousel.Caption>
           </StyledContainer>
         </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}
