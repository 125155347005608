/* eslint-disable react/style-prop-object */
import { Container, Col, Row } from "react-bootstrap";
import Iframe from "react-iframe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarker,
  faPhone,
  faSpoon,
} from "@fortawesome/free-solid-svg-icons";
export default function AddressMap({ id }) {
  return (
    <Container className="p-4" id={id}>
      <Row>
      <div className="mb-5 text-center">
        <span className="decoro-small decoro">Per Qualsiasi Dubbio</span>
        <h2 className="titolo-small titolo">Contattaci</h2>
        <div className="d-flex justify-content-center">
          <div className="spoon-line"></div>
          <FontAwesomeIcon
            className="spoon"
            icon={faSpoon}
            color="#c1a35f"
            size="2x"
          />
          <div className="spoon-line"></div>
        </div>
      </div>
      <Col lg={6}>
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d6142.910211623179!2d8.687955!3d39.661976!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sit!4v1693496381691!5m2!1sen!2sit"
          width="100%"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></Iframe>
      </Col>

      <Col lg={6}>
        <div className="mt-5">
          <span className="contatto">CONTATTO</span>
          <div className="mb-3"></div>
          <p className="w-100">
            Grazie per aver scelto Agriturismo Rixoris! Se hai domande
            riguardanti i nostri servizi, inviaci un messaggio e ti risponderemo
            al più presto. Se non ricevi una risposta entro 24 ore, puoi anche
            chiamarci al numero elencato di seguito. Di solito rispondiamo più
            velocemente ai messaggi inviati via WhatsApp.
          </p>
          <ul className="contatto-info">
            <li className="mb-2">
              <span className="me-2">
                <FontAwesomeIcon icon={faPhone} color="black" size="1x" />
              </span>
              +39 329 745 1720
            </li>
            <li className="mb-2">
              <span className="me-2">
                <FontAwesomeIcon icon={faEnvelope} color="black" size="1x" />
              </span>
              martino@rioxoris.it
            </li>
            <li className="mb-2">
              <span className="me-2">
                <FontAwesomeIcon icon={faMapMarker} color="black" size="1x" />
              </span>
              Oristano
            </li>
          </ul>
        </div>
      </Col>
      </Row>
    </Container>
  );
}
