
  

  export const camere = [
    
    {camere:'./Images/gallery/_DSC6040.jpg'},
    {camere:'./Images/gallery/_DSC6042.jpg'},
    {camere:'./Images/gallery/_DSC6044.jpg'},
    {camere:'./Images/gallery/_DSC6046.jpg'},
    {camere:'./Images/gallery/_DSC6048.jpg'},
    {camere:'./Images/gallery/_DSC6050.jpg'},
    {camere:'./Images/gallery/_DSC6051.jpg'},
    {camere:'./Images/gallery/_DSC6052.jpg'},
    {camere:'./Images/gallery/_DSC6054.jpg'},
    {camere:'./Images/gallery/_DSC6056.jpg'},
    {camere:'./Images/gallery/_DSC6058.jpg'},
    {camere:'./Images/gallery/_DSC6060.jpg'},
    {camere:'./Images/gallery/_DSC6062.jpg'},
    {camere:'./Images/gallery/_DSC6063.jpg'},
    {camere:'./Images/gallery/_DSC6068.jpg'},
    {camere:'./Images/gallery/_DSC6070.jpg'},
    {camere:'./Images/gallery/_DSC6073.jpg'},
    {camere:'./Images/gallery/_DSC6078.jpg'},
    {camere:'./Images/gallery/_DSC6079.jpg'},
    {camere:'./Images/gallery/_DSC6080.jpg'},
    {camere:'./Images/gallery/_DSC6081.jpg'},
    {camere:'./Images/gallery/_DSC6083.jpg'},
    {camere:'./Images/gallery/_DSC6086.jpg'},
    {camere:'./Images/gallery/_DSC6087.jpg'},
    {camere:'./Images/gallery/_DSC6089.jpg'},
    {camere:'./Images/gallery/_DSC6090.jpg'},
    {camere:'./Images/gallery/_DSC6091.jpg'},
    {camere:'./Images/gallery/_DSC6092.jpg'},
    {camere:'./Images/gallery/_DSC6093.jpg'},
    {camere:'./Images/gallery/_DSC6094.jpg'},
    {camere:'./Images/gallery/_DSC6095.jpg'},
    {camere:'./Images/gallery/_DSC6096.jpg'},
    {camere:'./Images/gallery/_DSC6098.jpg'},
    {camere:'./Images/gallery/_DSC6099.jpg'},
    {camere:'./Images/gallery/_DSC6100.jpg'},
    {camere:'./Images/gallery/_DSC6101.jpg'},
    {camere:'./Images/gallery/_DSC6102.jpg'},
    {camere:'./Images/gallery/_DSC6103.jpg'},
    {camere:'./Images/gallery/_DSC6116.jpg'},
    {camere:'./Images/gallery/_DSC6118.jpg'},
    {camere:'./Images/gallery/_DSC6120.jpg'},
    {camere:'./Images/gallery/_DSC6122.jpg'},
    {camere:'./Images/gallery/_DSC6123.jpg'},
    {camere:'./Images/gallery/_DSC6125.jpg'},
    {camere:'./Images/gallery/_DSC6126.jpg'},
    {camere:'./Images/gallery/_DSC6127.jpg'},
    {camere:'./Images/gallery/_DSC6128.jpg'},
    {camere:'./Images/gallery/_DSC6130.jpg'},
    {camere:'./Images/gallery/_DSC6131.jpg'},
    {camere:'./Images/gallery/_DSC6133.jpg'},
    {camere:'./Images/gallery/_DSC6136.jpg'},
    {camere:'./Images/gallery/_DSC6137.jpg'},
    {camere:'./Images/gallery/_DSC6138.jpg'},
    {camere:'./Images/gallery/_DSC6139.jpg'},
    {camere:'./Images/gallery/_DSC6140.jpg'},
    {camere:'./Images/gallery/_DSC6141.jpg'},
    {camere:'./Images/gallery/_DSC6142.jpg'},
    {camere:'./Images/gallery/_DSC6145.jpg'},
    {camere:'./Images/gallery/_DSC6147.jpg'},
    {camere:'./Images/gallery/_DSC6149.jpg'},
    {camere:'./Images/gallery/_DSC6150.jpg'},
    {camere:'./Images/gallery/_DSC6151.jpg'},
    {camere:'./Images/gallery/_DSC6152.jpg'},
    {camere:'./Images/gallery/_DSC6156.jpg'},
    {camere:'./Images/gallery/_DSC6157.jpg'},
    {camere:'./Images/gallery/_DSC6158.jpg'},
    {camere:'./Images/gallery/_DSC6159.jpg'},
    {camere:'./Images/gallery/_DSC6160.jpg'},
    {camere:'./Images/gallery/_DSC6164.jpg'},
   
  ];




export const images = [
   
  {agriturismo:'./Images/gallery/_DSC6378.jpg'},
  {agriturismo:'./Images/gallery/_DSC6382.jpg'},
  {agriturismo:'./Images/gallery/_DSC6384.jpg'},
  {agriturismo:'./Images/gallery/_DSC6399.jpg'},
  {agriturismo:'./Images/gallery/_DSC6398.jpg'},
  {agriturismo:'./Images/gallery/_DSC6192.jpg'},
  {agriturismo:'./Images/gallery/_DSC6196.jpg'},
  {agriturismo:'./Images/gallery/_DSC6197.jpg'},
  {agriturismo:'./Images/gallery/_DSC6202.jpg'},
  {agriturismo:'./Images/gallery/_DSC6206.jpg'},
  {agriturismo:'./Images/gallery/_DSC6207.jpg'},
  {agriturismo:'./Images/gallery/_DSC6208.jpg'},
  {agriturismo:'./Images/gallery/_DSC6209.jpg'},
  {agriturismo:'./Images/gallery/_DSC6211.jpg'},
  {agriturismo:'./Images/gallery/_DSC6212.jpg'},
  {agriturismo:'./Images/gallery/_DSC6215.jpg'},
  {agriturismo:'./Images/gallery/_DSC6037.jpg'},
  {agriturismo:'./Images/gallery/_DSC6254.jpg'},
  {agriturismo:'./Images/gallery/_DSC6255.jpg'},
  {agriturismo:'./Images/gallery/_DSC6259.jpg'},
  {agriturismo:'./Images/gallery/_DSC6260.jpg'},
  {agriturismo:'./Images/gallery/_DSC6266.jpg'},
  {agriturismo:'./Images/gallery/_DSC6270.jpg'},
  {agriturismo:'./Images/gallery/_DSC6271.jpg'},
  {agriturismo:'./Images/gallery/_DSC6272.jpg'},
  {agriturismo:'./Images/gallery/_DSC6275.jpg'}, 
  {animali:'./Images/gallery/_DSC6312.jpg'},
  {animali:'./Images/gallery/_DSC6316.jpg'},
  {animali:'./Images/gallery/_DSC6323.jpg'},
  {animali:'./Images/gallery/_DSC6328.jpg'},
  {animali:'./Images/gallery/_DSC6331.jpg'},
  {animali:'./Images/gallery/_DSC6334.jpg'},
  {animali:'./Images/gallery/_DSC6342.jpg'},
  {animali:'./Images/gallery/_DSC6346.jpg'},
  {animali:'./Images/gallery/_DSC6361.jpg'},
  {animali:'./Images/gallery/_DSC6365.jpg'},
  {animali:'./Images/gallery/_DSC6180.jpg'},
  {pietanze:'./Images/gallery/_DSC6014.jpg'},
  {pietanze:'./Images/gallery/_DSC6022.jpg'},
  {pietanze:'./Images/gallery/_DSC6025.jpg'}, 
  {pietanze:'./Images/gallery/_DSC6218.jpg'},
  {pietanze:'./Images/gallery/_DSC6219.jpg'},
  {pietanze:'./Images/gallery/_DSC6221.jpg'},
  {pietanze:'./Images/gallery/_DSC6222.jpg'},
  {pietanze:'./Images/gallery/_DSC6231.jpg'},
  {pietanze:'./Images/gallery/_DSC6248.jpg'},
  {pietanze:'./Images/gallery/_DSC6283.jpg'},
  {pietanze:'./Images/gallery/_DSC6289.jpg'},
  {pietanze:'./Images/gallery/_DSC6389.jpg'},
  {pietanze:'./Images/gallery/_DSC6391.jpg'},
  {pietanze:'./Images/gallery/_DSC6412.jpg'},

  {camere:'./Images/gallery/_DSC6040.jpg'},
  {camere:'./Images/gallery/_DSC6042.jpg'},
  {camere:'./Images/gallery/_DSC6044.jpg'},
  {camere:'./Images/gallery/_DSC6046.jpg'},
  {camere:'./Images/gallery/_DSC6048.jpg'},
  {camere:'./Images/gallery/_DSC6050.jpg'},
  {camere:'./Images/gallery/_DSC6051.jpg'},
  {camere:'./Images/gallery/_DSC6052.jpg'},
  {camere:'./Images/gallery/_DSC6054.jpg'},
  {camere:'./Images/gallery/_DSC6056.jpg'},
  {camere:'./Images/gallery/_DSC6058.jpg'},
  {camere:'./Images/gallery/_DSC6060.jpg'},
  {camere:'./Images/gallery/_DSC6062.jpg'},
  {camere:'./Images/gallery/_DSC6063.jpg'},
  {camere:'./Images/gallery/_DSC6068.jpg'},
  {camere:'./Images/gallery/_DSC6070.jpg'},
  {camere:'./Images/gallery/_DSC6073.jpg'},
  {camere:'./Images/gallery/_DSC6078.jpg'},
  {camere:'./Images/gallery/_DSC6079.jpg'},
];

